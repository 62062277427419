<template>
	<div>
		<template v-if="marketplace == 'mangopay'">         
			<RequiredInfosUser 
				v-if="require_infos"
				:callback="prepareInvoiceDetails"/>

			<form v-else @submit.prevent="payInvoiceDetails">

				<div class="row">
					<div class="col-12" v-if="form_message !== ''">
						<ErrorAlert :messageI18n="form_message" />
					</div>

					<div class="col-12">
						<h3>{{ $t('marketplace.amount_to_pay') }}</h3>
						<template v-for="(date, key) in dates">
							<div :key="key">
								<p v-if="key == current_date.toISOString()">{{ $t('marketplace.maintenant_contrat') }} : {{ date }} €</p>
								<p v-else>{{ key }} : {{ date }} €</p>
							</div>
						</template>
					</div>

					<div class="col-12">
						<label>{{ $t('marketplace.choix_compte_bancaire') }}</label>
						<e-select
							v-model="compte"
							track-by="id"
							label="formatted_libelle"
							:placeholder="getTrad('marketplace.choix_compte_bancaire')"
							:selectedLabel="getTrad('global.selected_label')"
							:options="formattedAccount"
							:allow-empty="true"
							:show-labels="false"
							:sortable="false"
							:loading="loadingAccount"
							:class="{ 'is-invalid': errors && errors.indexOf('account') > -1 }"
						></e-select>
					</div>

				</div>
				<b-button class="mt-3" v-if="compte != null" block @click.prevent="payInvoiceDetails">{{ $t("marketplace.payer") }}</b-button>
			</form>
		</template>
		<template v-else-if="marketplace == 'monetico'">
			<form @submit.prevent="payInvoiceDetails">

				<div class="row">
					<div class="col-12" v-if="form_message !== ''">
						<ErrorAlert :messageI18n="form_message" />
					</div>

					<div class="col-12">
						<h3>{{ $t('marketplace.amount_to_pay') }}</h3>
						<template v-for="(date, key) in dates">
							<div :key="key">
								<p v-if="key == current_date.toISOString()">{{ $t('marketplace.maintenant_contrat') }} : {{ date }} €</p>
								<p v-else>{{ key }} : {{ date }} €</p>
							</div>
						</template>
					</div>
				</div>
				<b-button class="mt-3" block @click.prevent="payInvoiceDetails">{{ $t("marketplace.payer") }}</b-button>
			</form>
		</template>
		<template v-else-if="marketplace == 'atos'">
			<form @submit.prevent="payInvoiceDetails">

				<div class="row">
					<div class="col-12" v-if="form_message !== ''">
						<ErrorAlert :messageI18n="form_message" />
					</div>

					<div class="col-12">
						<h3>{{ $t('marketplace.amount_to_pay') }}</h3>
						<template v-for="(date, key) in dates">
							<div :key="key">
								<p v-if="key == current_date.toISOString()">{{ $t('marketplace.maintenant_contrat') }} : {{ date }} €</p>
								<p v-else>{{ key }} : {{ date }} €</p>
							</div>
						</template>
					</div>
				</div>
				<b-button class="mt-3" block @click.prevent="payInvoiceDetails">{{ $t("marketplace.payer") }}</b-button>
			</form>

			<!-- Formulaire caché, il sert a renvoyer vers atos grace a une arnaque moldave -->
			<form method="post" :action="atos_infos.url" id="redirectForm" class="d-none">
				<input type="hidden" name="Data" :value="atos_infos.data">
				<input type="hidden" name="InterfaceVersion" value="HP_2.14">
				<input type="hidden" name="Seal" :value="atos_infos.seal">
				<button id="button_go_atos" onclick="">valider</button>
			</form>
		</template>
		<template v-else-if="marketplace == 'paybox'">
			<form @submit.prevent="payInvoiceDetails">

				<div class="row">
					<div class="col-12" v-if="form_message !== ''">
						<ErrorAlert :messageI18n="form_message" />
					</div>

					<div class="col-12">
						<h3>{{ $t('marketplace.amount_to_pay') }}</h3>
						<template v-for="(date, key) in dates">
							<div :key="key">
								<p v-if="key == current_date.toISOString()">{{ $t('marketplace.maintenant_contrat') }} : {{ date }} €</p>
								<p v-else>{{ key }} : {{ date }} €</p>
							</div>
						</template>
					</div>
				</div>
				<b-button class="mt-3" block @click.prevent="payInvoiceDetails">{{ $t("marketplace.payer") }}</b-button>
			</form>

			<!-- Formulaire caché, il sert a renvoyer vers atos grace a une arnaque moldave -->
			<form method="POST" :action="paybox_infos.url" id="redirectForm" class="d-none">
				<input type="hidden" name="PBX_SITE" :value="paybox_infos.PBX_SITE">
				<input type="hidden" name="PBX_RANG" :value="paybox_infos.PBX_RANG">
				<input type="hidden" name="PBX_IDENTIFIANT" :value="paybox_infos.PBX_IDENTIFIANT">
				<input type="hidden" name="PBX_TOTAL" :value="paybox_infos.PBX_TOTAL">
				<input type="hidden" name="PBX_DEVISE" :value="paybox_infos.PBX_DEVISE">
				<input type="hidden" name="PBX_RUF1" value="GET">
				<input type="hidden" name="PBX_CMD" :value="paybox_infos.PBX_CMD">
				<input type="hidden" name="PBX_PORTEUR" :value="paybox_infos.PBX_PORTEUR">
				<input type="hidden" name="PBX_REPONDRE_A" :value="paybox_infos.PBX_REPONDRE_A">
				<input type="hidden" name="PBX_RETOUR" :value="paybox_infos.PBX_RETOUR">
				<input type="hidden" name="PBX_EFFECTUE" :value="paybox_infos.PBX_EFFECTUE">
				<input type="hidden" name="PBX_ANNULE" :value="paybox_infos.PBX_ANNULE">
				<input type="hidden" name="PBX_REFUSE" :value="paybox_infos.PBX_REFUSE">
				<input type="hidden" name="PBX_HASH" value="SHA512">
				<input type="hidden" name="PBX_TIME" :value="paybox_infos.PBX_TIME">
	 			<input type="hidden" name="PBX_SHOPPINGCART" :value="paybox_infos.PBX_SHOPPINGCART">
				<input type="hidden" name="PBX_BILLING" :value="paybox_infos.PBX_BILLING">
				<input type="hidden" name="PBX_HMAC" :value="paybox_infos.PBX_HMAC">
				<button id="button_go_paybox" onclick="">valider</button>
			</form>
		</template>

		<template v-if="marketplace == 'sogecommerce'">
			<form @submit.prevent="payInvoiceDetails">

				<div class="row">
					<div class="col-12" v-if="form_message !== ''">
						<ErrorAlert :messageI18n="form_message" />
					</div>

					<div class="col-12">
						<h3>{{ $t('marketplace.amount_to_pay') }}</h3>
						<template v-for="(date, key) in dates">
							<div :key="key">
								<p v-if="key == current_date.toISOString()">{{ $t('marketplace.maintenant_contrat') }} : {{ date }} €</p>
								<p v-else>{{ key }} : {{ date }} €</p>
							</div>
						</template>
					</div>
				</div>
				<b-button class="mt-3" block @click.prevent="payInvoiceDetails">{{ $t("marketplace.payer") }}</b-button>
			</form>

			<!-- Formulaire caché, il sert a renvoyer vers atos grace a une arnaque moldave -->
			<form method="POST" :action="sogecommerce_infos.url" id="redirectForm" class="d-none">
				<input type="hidden" name="vads_action_mode" :value="sogecommerce_infos.vads_action_mode">
				<input type="hidden" name="vads_amount" :value="sogecommerce_infos.vads_amount">
				<input type="hidden" name="vads_ctx_mode" :value="sogecommerce_infos.vads_ctx_mode">
				<input type="hidden" name="vads_currency" :value="sogecommerce_infos.vads_currency">
				<input type="hidden" name="vads_page_action" :value="sogecommerce_infos.vads_page_action">
				<input type="hidden" name="vads_payment_config" :value="sogecommerce_infos.vads_payment_config">
				<input type="hidden" name="vads_site_id" :value="sogecommerce_infos.vads_site_id">
				<input type="hidden" name="vads_trans_date" :value="sogecommerce_infos.vads_trans_date">
				<input type="hidden" name="vads_trans_id" :value="sogecommerce_infos.vads_trans_id">
				<input type="hidden" name="vads_version" :value="sogecommerce_infos.vads_version">
				<input type="hidden" name="vads_payment_cards" :value="sogecommerce_infos.vads_payment_cards">
				<input type="hidden" name="vads_order_id" :value="sogecommerce_infos.vads_order_id">
				<input type="hidden" name="vads_cust_email" :value="sogecommerce_infos.vads_cust_email">
				<input type="hidden" name="vads_cust_id" :value="sogecommerce_infos.vads_cust_id">
				<input type="hidden" name="vads_cust_title" :value="sogecommerce_infos.vads_cust_title">
				<input type="hidden" name="vads_cust_status" :value="sogecommerce_infos.vads_cust_status">
				<input type="hidden" name="vads_cust_first_name" :value="sogecommerce_infos.vads_cust_first_name">
				<input type="hidden" name="vads_cust_last_name" :value="sogecommerce_infos.vads_cust_last_name">
				<input type="hidden" name="vads_cust_legal_name" :value="sogecommerce_infos.vads_cust_legal_name">
				<input type="hidden" name="vads_cust_phone" :value="sogecommerce_infos.vads_cust_phone">
				<input type="hidden" name="vads_cust_zip" :value="sogecommerce_infos.vads_cust_zip">
				<input type="hidden" name="vads_cust_city" :value="sogecommerce_infos.vads_cust_city">
				<input type="hidden" name="vads_cust_country" :value="sogecommerce_infos.vads_cust_country">
				<input type="hidden" name="signature" :value="sogecommerce_infos.signature">
				<button id="button_go_sogecommerce" onclick="">valider</button>
			</form>
		</template>
	</div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import ModelMixin from "@/mixins/Model.js"
	import TiersMixin from "@/mixins/Tiers.js"
	import HorseMixin from "@/mixins/Horse.js"
    import Marketplace from '@/mixins/Marketplace.js'
    import Invoice from '@/mixins/Invoice.js'

	import ShutterContract from "@/mixins/shutters-manager/Contract.js"
	import Shutter from '@/mixins/Shutter.js'

	export default {
		name: "PaymentForm",
		props: {
			invoicedetails_ids: {
				type: Array,
				default: () => ( [] )
			},
			details: {
				type: Array,
				default: () => ( [] )
			}
		},
		mixins: [Navigation, Marketplace, Invoice],
		data () {
			return {
				form_message: "",
				addOrEditReady: false,
				isDisabled: true,
				require_infos: true,
				table_busy: false,
				errors: [],
				

                has_invoice: false,
				invoice_mode: "after",

				items_to_pay: null,
                url_boutique: null,
                
				accounts: [],
                dates: {},
                go_atos: false,
                go_paybox: false,
                atos_infos: {},
                paybox_infos: {},
				sogecommerce_infos: {},
                marketplace: "",
                compte: null,
				current_date: new Date(),
				total_balance: 0,
			}
		},
		created() {
			this.getMarketplaceTypeLocal()
		},
		methods: {

			async init_component(){

			},
			
			async getMarketplaceTypeLocal(){

                let marketplace = await this.getMarketplaceType()
                this.marketplace = marketplace.retour.type
                if(this.marketplace == 'monetico')
                {
                    await this.prepareInvoiceDetails()
                }
                else if(this.marketplace == 'atos')
                {
                    await this.prepareInvoiceDetails()
                }
                else if(this.marketplace == 'paybox')
                {
                    await this.prepareInvoiceDetails()
                }
                else if(this.marketplace == 'sogecommerce')
                {
                    await this.prepareInvoiceDetails()
                }
                // this.modal_loaded = true
				EventBus.$emit('update:modal_loaded', true)
            },

            async prepareInvoiceDetails() {

                // this.modal_loaded = true
				EventBus.$emit('update:modal_loaded', true)
                this.require_infos = false

                if(this.marketplace == 'mangopay')
                {
                    this.loadPaymentMethod()
                }
                else if(this.marketplace == 'monetico')
                {
                    //
                }
                else if(this.marketplace == 'atos')
                {
                    //
                }
                else if(this.marketplace == 'paybox')
                {
                    //
                }

                // this.modal_loaded = false
				EventBus.$emit('update:modal_loaded', false)


                this.dates = {}
				this.total_balance = 0;
                for (let i = 0; i < this.details.length; i++)
                {

                    let date = new Date(this.details[i].invoice.invoice_date + " 00:00:00")

                    this.current_date.setHours(0)
                    this.current_date.setMinutes(0)
                    this.current_date.setSeconds(0)
                    let date_tab = date
                    if(date <= this.current_date)
                    {
                        date_tab = this.current_date
                    }

                    let balance = this.details[i].invoice.invoice_balance
                    balance = balance * 1

					this.total_balance += balance
                    
					if(this.dates[date_tab.toISOString()])
                    {
                        this.dates[date_tab.toISOString()] += balance / 100
                    }
                    else
                    {
                        this.dates[date_tab.toISOString()] = balance / 100
                    }
                }

                // this.modal_loaded = true
				EventBus.$emit('update:modal_loaded', true)

            },

            async payInvoiceDetails(){

                if(this.marketplace == 'mangopay')
                {
                    await this.markToPaymentInvoiceDetails(this.invoicedetails_ids, this.compte.id)
                    this.$refs['modal'].hide()
                    this.init_component()
                }
                else if(this.marketplace == 'monetico')
                {
                    let url_paiment = await this.redirectBoutiqueDetails(this.invoicedetails_ids, this.total_balance)
                    if(url_paiment != null)
                    {
                        document.location.href = url_paiment.retour.url
                    }

                    this.$refs['modal'].hide()
                    this.init_component()
                }
                else if(this.marketplace == 'atos')
                {
                    let datas_payment = await this.redirectBoutiqueDetails(this.invoicedetails_ids, this.total_balance)
                    if(datas_payment != null && datas_payment.retour.url != null)
                    {
                       
                        this.atos_infos = {
                            url: datas_payment.retour.url,
                            data: datas_payment.retour.data,
                            seal: datas_payment.retour.key,
                        }

                        //A voir pour améliorer, le nextick ne fonctionne pas, ou alors j'arrive pas à l'utiliser correctement
                        setTimeout(() => {
                            document.querySelector("#button_go_atos").click()

                        },0)
                    }
                }
                else if(this.marketplace == 'paybox')
                {
                    let datas_payment = await this.redirectBoutiqueDetails(this.invoicedetails_ids, this.total_balance)

                    if(datas_payment != null && datas_payment.retour.url != null)
                    {
                        this.paybox_infos =  datas_payment.retour

                        //A voir pour améliorer, le nextick ne fonctionne pas, ou alors j'arrive pas à l'utiliser correctement
                        setTimeout(() => {
                            document.querySelector("#button_go_paybox").click()
                        },0)
                    }
                }
				else if(this.marketplace == 'sogecommerce') 
				{
                    let datas_payment = await this.redirectBoutiqueDetails(this.invoicedetails_ids, this.total_balance)

					if(datas_payment != null && datas_payment.retour.url != null)
					{
						this.sogecommerce_infos =  datas_payment.retour

						//A voir pour améliorer, le nextick ne fonctionne pas, ou alors j'arrive pas à l'utiliser correctement
						setTimeout(() => {
							document.querySelector("#button_go_sogecommerce").click()
						},0)
					}
				}
            },

			async loadPaymentMethod() {
                this.loadingAccount = true
                this.working = true
                
                this.getPaymentMethod(true)
                .then(r => {
                    this.accounts = r.retour
                    this.loadingAccount = false
                    this.working = false
                })
                .catch(e => {
                })
            },
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
            RequiredInfosUser : () => import('GroomyRoot/components/Marketplace/RequiredInfosUser'),
		}
	}
</script>
